import React from "react";
import withI18next from "../components/withI18next";
import { Link } from "gatsby";
const Success = ({ pageContext: { locale }, t }) => {
  return (
    <>
      <h1>{t("message_sent_successfully")}</h1>
      <Link to={`/${locale === "hu" ? "" : locale}/`}>{t("goBackHome")}</Link>
    </>
  );
};
export default withI18next()(Success);
